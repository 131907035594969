var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.reportSettings && _vm.category ? _c('report-page', [_c('div', {
    staticClass: "category-path"
  }, [_c('category-path', {
    attrs: {
      "path": _vm.category.c_id_path,
      "categories": _vm.category.categories,
      "light": ""
    }
  })], 1), _c('main-title', {
    scopedSlots: _vm._u([{
      key: "action",
      fn: function () {
        return [_vm.category ? _c('list-add-item-button', {
          attrs: {
            "entity": "category",
            "mp": _vm.$route.params.mp,
            "item": _vm.category
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e(), _c('switch-input', {
          attrs: {
            "label": "FBS"
          },
          model: {
            value: _vm.fbs,
            callback: function ($$v) {
              _vm.fbs = $$v;
            },
            expression: "fbs"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 2834207828)
  }, [_vm._v(" Обзор показателей по категории "), _c('span', {
    staticClass: "blue-text"
  }, [_vm._v(_vm._s(_vm.category && _vm.category.c_name))])]), _vm.reportSettings ? _c('entity-charts', {
    attrs: {
      "cells": _vm.cells,
      "loader-config": _vm.reportSettings,
      "loader": _vm.loadOverview,
      "period": _vm.calendar
    }
  }) : _vm._e(), _c('main-title', [_vm._v(" Статистика продаж по категории ")]), _c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_tables,
      "route-param": "tab"
    },
    model: {
      value: _vm.tabs_tables_model,
      callback: function ($$v) {
        _vm.tabs_tables_model = $$v;
      },
      expression: "tabs_tables_model"
    }
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings && _vm.tabs_tables_model ? _c(_vm.getTableComponent(_vm.tabs_tables_model.id), {
    key: _vm.tableKey,
    tag: "component",
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "track": _vm.track,
      "file-name": _vm.fileName
    }
  }) : _vm._e()], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };