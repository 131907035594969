import "core-js/modules/es.array.reduce.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.iterator.some.js";
import DataTable from "@/components/DataTable/DataTable";
import { dateRangeDays } from "@/utils/dates";
import { toExportFormat } from "@/utils/export";
import _ from "lodash";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    forceLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      oldFilters: {}
    };
  },
  methods: {
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 10000;
        query.page = 1;
      }
      if (query.field) {
        return this.$store.dispatch("category/getCategoryFilter", {
          ...query,
          ...this.reportSettings
        });
      } else {
        let filters = null;
        const keys = Object.keys(query);
        if (keys.some(key => key.startsWith("f."))) {
          filters = keys.filter(key => key.startsWith("f.")).reduce((acc, key) => {
            acc[key] = query[key];
            return acc;
          }, {});
        }
        const body = {
          ...query,
          ...this.reportSettings
        };
        if (filters) {
          body.filters = filters;
        }
        if (!_.isEqual(filters, this.oldFilters)) {
          this.$emit("updateFilters", filters);
        }
        this.oldFilters = {
          ...filters
        };
        try {
          const result = await this.$store.dispatch("category/getCategoryBrands", body);
          if (query.action === "export") {
            return toExportFormat(result, this.columns);
          } else {
            return result;
          }
        } catch (e) {
          const errorMessage = e.message;
          if (typeof errorMessage === "string" && errorMessage !== "no-limit-error") {
            this.$emit("limitReached", errorMessage);
          }
        }
      }
    }
  },
  computed: {
    columns() {
      const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
      return [{
        title: "Бренд",
        show: true,
        name: "brand",
        width: 440,
        filter: "select",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "BrandInfo",
              params: {
                b_id: item.b_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.brand,
            target: "_blank"
          };
        }
      }, {
        title: "Товаров",
        show: true,
        name: "products",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Товаров с продажами",
        show: true,
        name: "products_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Рейтинг товаров",
        show: true,
        name: "rating",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Отзывы",
        show: true,
        name: "reviews",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Сред. отзывов на товар",
        show: true,
        name: "avg_reviews",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Продано за ${days} дн (шт)`,
        show: true,
        name: "sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Выручка за ${days} дн`,
        show: true,
        name: "revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Сред. продаж в день (шт)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `Сред. выручка в день`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};