var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', _vm._b({
    attrs: {
      "table-id": "category_products",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "p_id"
    },
    on: {
      "selectAction": $event => $event.addToList({
        entity: 'product',
        mp: this.reportSettings.mp
      }),
      "selectActionM": $event => $event.addToListM({
        entity: 'product',
        mp: this.reportSettings.mp
      })
    },
    scopedSlots: _vm._u([{
      key: "category",
      fn: function ({
        item,
        categories
      }) {
        return [_c('product-categories', {
          attrs: {
            "product-categories": item.categories_list,
            "base-categories": categories,
            "route-query": {
              date: _vm.reportSettings.date,
              date2: _vm.reportSettings.date2,
              fbs: _vm.reportSettings.fbs
            }
          }
        })];
      }
    }, {
      key: "s_inn",
      fn: function ({
        item
      }) {
        return [item.s_inn != '' ? _c('span', {
          domProps: {
            "innerHTML": _vm._s(item.s_inn)
          }
        }) : _c('span', {
          staticClass: "gray",
          attrs: {
            "title": "Нет данных"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.NA)
          }
        })];
      }
    }, {
      key: "s_ogrn",
      fn: function ({
        item
      }) {
        return [item.s_ogrn != '' ? _c('span', {
          domProps: {
            "innerHTML": _vm._s(item.s_ogrn)
          }
        }) : _c('span', {
          staticClass: "gray",
          attrs: {
            "title": "Нет данных"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.NA)
          }
        })];
      }
    }, {
      key: "s_id_BZ",
      fn: function ({
        item
      }) {
        return [item.s_id_BZ > 0 ? _c('span', [_c('a', {
          staticClass: "link",
          attrs: {
            "href": _vm.href_s_id_BZ(item),
            "target": "_blank"
          }
        }, [_vm._v(" да ")])]) : _c('span', {
          staticClass: "gray",
          domProps: {
            "innerHTML": _vm._s(`нет`)
          }
        })];
      }
    }])
  }, 'data-table', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };