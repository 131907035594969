var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', _vm._b({
    attrs: {
      "table-id": "category_sellers",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "s_id",
      "forceLoading": _vm.forceLoading
    },
    on: {
      "selectAction": $event => $event.addToList({
        entity: 'seller',
        mp: this.reportSettings.mp
      }),
      "selectActionM": $event => $event.addToListM({
        entity: 'seller',
        mp: this.reportSettings.mp
      })
    },
    scopedSlots: _vm._u([{
      key: "s_inn",
      fn: function ({
        item
      }) {
        return [item.s_inn != '' ? _c('span', {
          domProps: {
            "innerHTML": _vm._s(item.s_inn)
          }
        }) : _c('span', {
          staticClass: "gray",
          attrs: {
            "title": "Нет данных"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.NA)
          }
        })];
      }
    }, {
      key: "s_ogrn",
      fn: function ({
        item
      }) {
        return [item.s_ogrn != '' ? _c('span', {
          domProps: {
            "innerHTML": _vm._s(item.s_ogrn)
          }
        }) : _c('span', {
          staticClass: "gray",
          attrs: {
            "title": "Нет данных"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.NA)
          }
        })];
      }
    }, {
      key: "s_id_BZ",
      fn: function ({
        item
      }) {
        return [item.s_id_BZ > 0 ? _c('span', [_c('a', {
          staticClass: "link",
          attrs: {
            "href": _vm.href_s_id_BZ(item),
            "target": "_blank"
          }
        }, [_vm._v(" да ")])]) : _c('span', {
          staticClass: "gray",
          domProps: {
            "innerHTML": _vm._s(`нет`)
          }
        })];
      }
    }])
  }, 'data-table', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };