import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lists-page__none",
    class: {
      small: _vm.small
    }
  }, [!_vm.small ? _c('div', {
    staticClass: "lists-page__none-title"
  }, [_vm._v(" " + _vm._s(this.title) + " ")]) : _vm._e(), _c('div', {
    staticClass: "lists-page__none-text",
    class: {
      small: _vm.small
    }
  }, [_vm._t("default")], 2), _c('button', {
    staticClass: "btn-outline btn-width-auto",
    class: {
      'connect-btn': _vm.small
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push('/profile/plan');
      }
    }
  }, [_vm._v(" Перейти в личный кабинет ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };