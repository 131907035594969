var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', _vm._b({
    attrs: {
      "table-id": "category_keywords",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "k_id",
      "selectable": false
    },
    on: {
      "selectAction": $event => $event.addToList({
        entity: 'keyword',
        mp: this.reportSettings.mp
      })
    }
  }, 'data-table', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };