import "core-js/modules/esnext.iterator.map.js";
import { Doughnut /*, mixins */ } from "vue-chartjs";
// const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  // mixins: [reactiveProp],
  data() {
    return {
      gradient: null
    };
  },
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  computed: {
    chartDataLocal() {
      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      const bgGenerators = {
        gradient: dataset => {
          const {
            r,
            g,
            b
          } = hexToRgb(dataset.borderColor);
          const gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
          gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0)`);
          gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0.2)`);
          return gradient;
        },
        smallGradient: dataset => {
          const {
            r,
            g,
            b
          } = hexToRgb(dataset.borderColor);
          const gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 100);
          gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0)`);
          gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0.2)`);
          return gradient;
        },
        reversedGradient: dataset => {
          const {
            r,
            g,
            b
          } = hexToRgb(dataset.borderColor);
          const gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 80);
          gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0.4)`);
          gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0)`);
          return gradient;
        }
      };
      const getBackgroundColor = dataset => {
        if (Object.keys(bgGenerators).includes(dataset.backgroundColor)) {
          return bgGenerators[dataset.backgroundColor](dataset);
        }
        return dataset.backgroundColor;
      };
      return {
        ...this.chartData,
        datasets: this.chartData.datasets.map(dataset => {
          return {
            ...dataset,
            data: dataset.data.map(i => i < 0 ? 0 : i),
            backgroundColor: getBackgroundColor(dataset)
          };
        })
      };
    }
  },
  mounted() {
    this.renderChart(this.chartDataLocal, this.options);
  },
  watch: {
    options: {
      handler(v) {
        this.renderChart(this.chartDataLocal, v);
      },
      deep: true
    }
  }
};