/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./BrandsTable.vue?vue&type=template&id=2383731e"
import script from "./BrandsTable.vue?vue&type=script&lang=js"
export * from "./BrandsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports